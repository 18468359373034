import clsx from "clsx";
import React from "react";
import { PlayButton } from "./PlayButton";
interface Props {
  title: string | undefined;
  darkMode?: boolean;
}
export const PlayControls: React.FC<Props> = ({
  title,
  darkMode
}) => {
  return title ? <PlayControlsWithTitle title={title} darkMode={darkMode} data-sentry-element="PlayControlsWithTitle" data-sentry-component="PlayControls" data-sentry-source-file="PlayControls.tsx" /> : <PlayControlsCentered darkMode={darkMode} data-sentry-element="PlayControlsCentered" data-sentry-component="PlayControls" data-sentry-source-file="PlayControls.tsx" />;
};
const PlayControlsWithTitle: React.FC<Required<Props>> = ({
  title,
  darkMode
}) => <div className="absolute bottom-0 left-0 right-0 flex flex-row items-center px-3 py-3 md:px-6 md:py-5 lg:py-9 lg:px-10" data-sentry-component="PlayControlsWithTitle" data-sentry-source-file="PlayControls.tsx">
    <PlayButton darkMode={darkMode} data-sentry-element="PlayButton" data-sentry-source-file="PlayControls.tsx" />
    <span className={clsx("text-h3 sm:text-e5 ml-2 flex-1 line-clamp-1 lg:ml-4", {
    "text-beige-64": !darkMode,
    "text-plum": darkMode
  })}>
      {title}
    </span>
  </div>;
export const PlayControlsCentered: React.FC<Pick<Props, "darkMode">> = ({
  darkMode
}) => {
  return <div className="absolute inset-0 flex flex-1 items-center justify-center" data-sentry-component="PlayControlsCentered" data-sentry-source-file="PlayControls.tsx">
      <PlayButton darkMode={darkMode} data-sentry-element="PlayButton" data-sentry-source-file="PlayControls.tsx" />
    </div>;
};